import {  Navigate, useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, query, where,updateDoc,addDoc } from "firebase/firestore";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";

import ReactStars from "react-stars";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload,faCheck,faSpinner,faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { useNavigate } from 'react-router-dom';

function MovieDetalle() {

  
  


  const firebaseConfig = {
    apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
    authDomain: "movies-fc2b1.firebaseapp.com",
    projectId: "movies-fc2b1",
    storageBucket: "movies-fc2b1.appspot.com",
    messagingSenderId: "1028587674738",
    appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
    measurementId: "G-ZQNNHLY8DE",
  };
  const navigate = useNavigate();

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const { id } = useParams();
  const [movie, setMovie] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingLista, setLoadingLista] = useState(true);
  const [generos, setGeneros] = useState([]);
  const [estadosPeliculas, setEstadosPeliculas] = useState([]);
  const [descargando, setDescargando] = useState(false);
  const [seccion, setSeccion] = useState("descripcion");
  const [trailer, setTrailer] = useState("");
  const [trailerEscaneado, setTrailerEscaneado] = useState(false);

  //ejecutar un useEffect para obtener los datos de la pelicula cada 10 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      obtenerMovies();
    }, 10000);
    return () => clearInterval(interval);
  }, []); 

  




  const obtenerMovies = async () => {
    const db = getFirestore(app);
    const q = query(collection(db, "movies"), where("uid", "==",id ));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {      //enviar a 404
      navigate("/404");

    }

    const docs = querySnapshot.docs.map((doc) => doc.data());

    //console.table(docs[0]);
    
    setMovie(docs[0]);
    
    setLoading(false);
    
    setLoadingLista(false);

  };

  const obtenerGenerosTMDB = async () => {
    const url = `https://api.themoviedb.org/3/genre/movie/list?api_key=27e3e0e4de6cec566e26c2c089566fe7&language=es-MX`;
    const resp = await fetch(url);
    const data = await resp.json();
    setGeneros(data.genres);
    obtenerMovies();
  };

  const obtenerTrailerTMDB = async () => {
    const url = `https://api.themoviedb.org/3/movie/${movie.metadatos_the_movie_db.id}/videos?api_key=27e3e0e4de6cec566e26c2c089566fe7&language=es-MX`;
    const resp = await fetch(url);
    const data = await resp.json();

    if (data.results.length > 0) {
      setTrailer("https://www.youtube.com/embed/" + data.results[0].key);
    }else{
      const url2= `https://api.themoviedb.org/3/movie/${movie.metadatos_the_movie_db.id}/videos?api_key=27e3e0e4de6cec566e26c2c089566fe7&language=en-US`;
      const resp2 = await fetch(url2);
      const data2 = await resp2.json();
      if (data2.results.length > 0) {
        setTrailer("https://www.youtube.com/embed/" + data2.results[0].key);
      }
    }

    console.log(data);
  };

  //disable class by id 
  const changeClass = (uuid) => {
    document.getElementById(uuid).classList.toggle("is-loading");
    
  };

  const descargaPelicula = async (id, uuid) => {
    setDescargando(true);
    console.log("Hola descargando")
    //update movie status
    const db = getFirestore(app);
    const q = query(collection(db, "movies"), where("uid", "==",id ));
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map((doc) => doc.data());
    let links = docs[0].links;
    let url = "";

    //recorrer links
    links.forEach((link) => {
      link.enlaces.forEach((enlace) => {
        if (enlace.uuid == uuid) {
          enlace.estado = 1;
          url = enlace.url;
        }
      });
    });


    //update docs
    const docRef = collection(db, "movies");

    const doc = await getDocs(docRef);

    doc.forEach((doc) => {
      if (doc.data().uid == id) {
        updateDoc(doc.ref, {
          links: links,
        });
      }
    });



    //insertar en la lista de descargas
    //quitar _ y # de la url
    let url_limpia= url.replace(/_/g, "");
    url_limpia= url_limpia.replace(/#/g, "");

    
    const docRef2 = collection(db, "descargas");
    const doc2 = await addDoc(docRef2, {
      uuid: uuid,
      id: id,
      url: url,
      url_limpia: url_limpia,
      estado: 0,
      fecha: new Date(),
      renombrado: 0,
    });

    
    obtenerMovies();	

    setDescargando(false);
    
   

  };



  useEffect(() => {
    
    obtenerGenerosTMDB();
    
  }, []);

  useEffect(() => {
    //Si movie tiene datos, obtener trailer
    if (Object.keys(movie).length > 0 ){
      if(!trailerEscaneado){
        setTrailerEscaneado(true);
        obtenerTrailerTMDB();
      }
    }
  }, [movie]);

  return (
    <>
      {loading ? (
        //centrar el loader
        <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ClipLoader size={50} color={"#3e8ed0"} />
          </div>
        
      ) : (
        <>
          <div className="columns is-mobile">
            <div className="column ">
              <div className="card" >
                <div className="columns ">
                  <div className="column is-3">
                    <div className="card-image">
                      <figure className="image is-2by3">
                        <img
                          alt=""
                          src={
                            "https://image.tmdb.org/t/p/w500" +
                            movie.portada
                          }
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="column is-9">
                  <div className="tabs">
                    <ul>
                      <li className={seccion == 'descripcion' ? "is-active" : ""} onClick={() => setSeccion("descripcion")}><a>Descripción</a></li>
                      <li  className={seccion == 'trailer' ? "is-active" : ""} onClick={() => setSeccion("trailer")}  style={{display: trailer != "" ? 'block' : 'none'}}><a>Trailer</a></li>
                    </ul>

                  </div>
                    <div className="card-content" id='descripcion' style={{display: seccion == 'descripcion' ? 'block' : 'none'}}>
                      <div className="media">
                        <div className="media-content" style={{overflowX:'inherit'}}>
                          <p className="title is-4">{movie.titulo_sin_info}</p>
                          <p className="subtitle is-6">
                            {movie.metadatos_the_movie_db.original_title}
                          </p>
                          <ReactStars
                            count={5}
                            size={24}
                            color2={"#ffd700"}
                            value={movie.metadatos_the_movie_db.vote_average / 2}
                            edit={false}
                          />
                          <p className="subtitle is-6">
                            {movie.metadatos_the_movie_db.release_date}
                          </p>
                          <p className="subtitle is-6">
                            {movie.metadatos_the_movie_db.overview}
                          </p>

                            <div className="tags">
                              {movie.metadatos_the_movie_db.genre_ids.map((genero) => {
                                return (
                                  <span className="tag is-info" key={genero}>
                                    {generos.find((g) => g.id === genero).name }
                                  </span>
                                );
                              })}
                            </div>
                          
                        </div>
                      </div>
                    </div>

                    <div className="card-content" id='descripcion' style={{display: seccion == 'trailer' ? 'block' : 'none'}}>
                      <div className="media">
                        <div className="media-content" style={{overflowX:'inherit'}}>
                          <div className="video-container">
                              <iframe src={trailer} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <div className="table-container">

                    {!loadingLista ? (
                      <>
                    <table className="table is-striped is-fullwidth">
                      <thead>
                        <tr>
                          <th>Calidad</th>
                          <th>Tipo de rip</th>
                          <th>Enlaces</th>
                          <th>Origen</th>
                          <th>Fecha de registro</th>
                        </tr>
                      </thead>
                      <tbody>
                        {movie.links.map((link, index) => {
                          return (
                            <tr key={index}>
                              <td>{link.calidad}</td>
                              <td>{link.rip}</td>
                            
                          
                              <td>
                                {link.enlaces.map((enlacess, index) => {
                                  return (
                                    <>
                                    <div key={index} style={{display:"flex", justifyContent:"space-between"}}>
                                      
                                      
                                    <a href={enlacess.url} target="_blank" rel="noreferrer">
                                    {/* <button
                                        className="button is-success is-small">
                                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                    </button> */}
                                    </a>
                                       <p
                                       
                                       >{enlacess.url}  </p>
                                  


                                    {enlacess.estado !=1 ? (
                                      <button
                                        className={enlacess.estado == 0 ? "button is-primary is-small" : "button is-success is-small"}
                                        
                                        onClick={() => {
                                          !descargando?descargaPelicula(id, enlacess.uuid):console.log("descargando");
                                        }

                                        
                                        
                                        }

                                        disabled = {enlacess.estado > 0 ? true : false}

                                        >
                                          {enlacess.estado == 0 ? (
                                        <FontAwesomeIcon icon={faDownload} />
                                      ) : enlacess.estado == 1 ? (
                                        <FontAwesomeIcon icon={faSpinner} />
                                      ) :
                                      
                                      (
                                        <FontAwesomeIcon icon={faCheck} />
                                      )}
                                        </button>
                                    ) : (
                                      <></>
                                    )}

                                    </div>
                                    {enlacess.estado == 1 ? (
                                      <>
                                        {enlacess.porcentaje}% de descarga
                                    <progress className="progress is-success" value={enlacess.porcentaje} max="100">{enlacess.porcentaje}%</progress>
                                  
                                    </>
                                    ) : (
                                      <></>
                                    )}
                                    </>


                                  );
                                })}
                              </td>
                              <td style={{display:"flex", justifyContent:"space-between"}}>
                              {link.origen}
                                <a href={link.origen} target="_blank" rel="noreferrer">
                              <button
                                className="button is-success is-small">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </button>
                                </a></td>
                             

                              <td>
                                {link.fecha_registro
                                  ? link.fecha_registro
                                      .toDate()
                                      .toLocaleString()
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>
                   
                    </>
                    ) : (
                      <div
                        className="container"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ClipLoader size={50} color={"#3e8ed0"} />
                      
                      </div>
                    )}

                  </div>
                  <div>
                    <p>Las versiones BRRip = 4gb </p>
                    <p>Las versiones BDRip = 8gb </p>
                    <p>Las versiones WEB-DL = 8gb </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer limit={1} />
    </>
  );
}

export default MovieDetalle;
